import React from "react";

function AboutMe() {
  return (
    <section className="aboutme">
      <div className="container">
        <div className="row">
          <div className="text-center">
            <h2>Selam!</h2>
            <p>
              Osman Tuğra Dündar, Dünya mutfağı aşçısıdır. 19 yaşında dinamik
              bir lider ve toplum aktivistidir. Şu anda Zafer Partisi Sarıyer
              Gençlik Kolları Başkanı olarak görev yapmaktadır. Aynı zamanda
              ulusal öğrenci konseyinin genel sekreteri ve Disiplin Kurulu
              Başkanıdır. Genç yaşına rağmen birçok sorumluluk üstlenen Tuğra,
              Sarıyerli Türkçüler Genel Başkan Yardımcılığı görevini de
              yürütmektedir.
            </p>
            <p>
              Kitap okumaya ve internette gezinmeye büyük bir tutku duyan Tuğra,
              5 Ekim 2005 tarihinde doğmuştur. Müzik yeteneğiyle de dikkat çeken
              Dündar, gitar ve dombıra çalmaktadır. Gençliğin gücüne inanarak,
              toplumsal değişim ve gelişim için çalışmaya devam eden Tuğra,
              hedefleri doğrultusunda azimle ilerlemektedir.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
