import React from "react";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-6">
            <h2>OSMAN TUĞRA DÜNDAR</h2>
            <p>
              Bu internet sitesi herhangi bir kuruma bağlı olmayıp <br />{" "}
              kendimi tanıtmak amacıyla yapılmıştır.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
