import React from "react";
import BannerImg from "./img-1.webp";

function Banner() {
  return (
    <section className="banner">
      <img src={BannerImg} alt="" className="w-100 h-100" />
      <div className="container">
        <div className="row banner-content">
          <h1>OSMAN TUĞRA DÜNDAR</h1>
          <ul className="m-0">
            <li className="me-2">Gastronomi</li>
            <li className="me-2"> • </li>
            <li>Siyaset</li>
          </ul>
          <div className="social-icons">
            <ul className="m-0">
              <li>
                <a href="#">
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fa-solid fa-envelope"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
