import React from "react";
import Img1 from "./img-1.jpeg";
import Img2 from "./img-2.jpeg";
import Img3 from "./img-3.jpeg";
import Img4 from "./img-4.jpeg";
import Img5 from "./img-5.jpeg";
import Img6 from "./img-6.jpeg";

function Gallery() {
  return (
    <section className="gallery">
      <div className="container">
        <div className="row gy-5">
          <h2>Fotoğraflarım</h2>
          <div className="col-lg-6">
            <img src={Img1} alt="" className="w-100" />
          </div>
          <div className="col-lg-6">
            <img src={Img2} alt="" className="w-100" />
          </div>
          <div className="col-lg-6">
            <img src={Img3} alt="" className="w-100" />
          </div>
          <div className="col-lg-6">
            <img src={Img4} alt="" className="w-100" />
          </div>
          <div className="col-lg-6">
            <img src={Img5} alt="" className="w-100" />
          </div>
          <div className="col-lg-6">
            <img src={Img6} alt="" className="w-100" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
