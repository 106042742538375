import React from "react";

function Skills() {
  return (
    <section className="skills">
      <div className="container">
        <div className="row">
          <h2>Becerilerim</h2>
          <div className="col-lg-4">
            <div class="progress blue">
              <span class="progress-left">
                <span class="progress-bar"></span>
              </span>
              <span class="progress-right">
                <span class="progress-bar"></span>
              </span>
              <div class="progress-value">
                <span class="label">Mutfak</span>
                <br />
                <span class="percent">90%</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div class="progress blue">
              <span class="progress-left">
                <span class="progress-bar"></span>
              </span>
              <span class="progress-right">
                <span class="progress-bar"></span>
              </span>
              <div class="progress-value">
                <span class="label">Tasarım</span>
                <br />
                <span class="percent">90%</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div class="progress blue">
              <span class="progress-left">
                <span class="progress-bar"></span>
              </span>
              <span class="progress-right">
                <span class="progress-bar"></span>
              </span>
              <div class="progress-value">
                <span class="label">Liderlik</span>
                <br />
                <span class="percent">90%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
