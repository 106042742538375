import React from "react";
import Banner from "../../containers/Banner/Banner";
import AboutMe from "../../containers/AboutMe/AboutMe.jsx";
import Skills from "../../containers/Skills/Skills.jsx";
import Gallery from "../../containers/Gallery/Gallery.jsx";
import Footer from "../../containers/Footer/Footer.jsx";

function Home() {
  return (
    <div>
      <Banner />
      <AboutMe />
      <Skills />
      <Gallery />
      <Footer />
    </div>
  );
}

export default Home;
